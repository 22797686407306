var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      !_vm.previewDialog
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE &&
              _vm.currentTables.length > 0
                ? _c(
                    "v-banner",
                    {
                      staticClass: "mb-5",
                      attrs: { "single-line": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function() {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      to: {
                                        name: "snapshot-tables",
                                        params: {
                                          oid: _vm.$route.params.oid,
                                          sid: _vm.$route.params.sid,
                                          iid: _vm.$route.params.iid,
                                          snid: _vm.$route.params.snid
                                        }
                                      },
                                      text: "",
                                      color: "secondary"
                                    }
                                  },
                                  [_vm._v("Browse tables")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      href:
                                        "https://docs.nuvolos.cloud/actions/work-with-data-in-nuvolos",
                                      target: "_blank",
                                      text: "",
                                      color: "secondary"
                                    }
                                  },
                                  [_vm._v("How to handle data in Nuvolos?")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2829304723
                      )
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          attrs: { slot: "icon", color: "white", size: "30" },
                          slot: "icon"
                        },
                        [
                          _c("v-icon", { attrs: { color: "info" } }, [
                            _vm._v("info")
                          ])
                        ],
                        1
                      ),
                      _vm._v("This dataset contains dataset tables. ")
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("TheSnapshotMoveAndCopyFiles", {
                        attrs: {
                          selectedFiles: _vm.filesToMoveOrCopy,
                          operationType: _vm.copyOrMoveOperation,
                          folderTypeSelected: _vm.folderTypeSelected
                        },
                        on: {
                          clearCopyAndMoveFiles: function($event) {
                            return _vm.clearCopyAndMoveFiles()
                          },
                          removeFileFromMoveCopyList: function($event) {
                            return _vm.removeFileFromMoveCopyList($event.fid)
                          }
                        }
                      }),
                      _vm.currentSnapshotHasFileSystemPrefix
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-space-between",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-inline" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "primary--text font-weight-bold"
                                    },
                                    [_vm._v("Files")]
                                  ),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        disabled: _vm.filesFetching,
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "caption font-weight-bold ml-2",
                                                      attrs: {
                                                        small: "",
                                                        text: "",
                                                        outlined: "",
                                                        color: "primary"
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.folderTypeSelected ===
                                                            _vm
                                                              .folderTypesLabels
                                                              .WORKSPACE_FILES
                                                            ? "WORKSPACE"
                                                            : "PERSONAL"
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "keyboard_arrow_down"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3644810289
                                      )
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        { attrs: { nav: "", dense: "" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          _vm._g(
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeFileType(
                                                                    _vm
                                                                      .folderTypesLabels
                                                                      .WORKSPACE_FILES
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  "Workspace"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1216793858
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Public files visible to all instance editors/viewers."
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { right: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          _vm._g(
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeFileType(
                                                                    _vm
                                                                      .folderTypesLabels
                                                                      .PERSONAL_FILES
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  "Personal"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                384607394
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Files visible to you only."
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center"
                                },
                                [
                                  _vm.quotaPercentageUsage !== null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                color:
                                                  _vm.quotaPercentageUsage <= 90
                                                    ? "primary"
                                                    : "error",
                                                outlined: "",
                                                small: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "caption font-weight-medium"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.quotaPercentageUsage +
                                                          "% of " +
                                                          parseFloat(
                                                            (
                                                              _vm.snapshotQuota
                                                                .bytes_quota /
                                                              Math.pow(1024, 3)
                                                            ).toFixed(2)
                                                          ) +
                                                          "GB"
                                                      ) +
                                                      " used "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isDevelopment === true,
                                          expression: "isDevelopment === true"
                                        }
                                      ]
                                    },
                                    [
                                      _c("UppyUpload", {
                                        staticClass: "d-inline",
                                        attrs: {
                                          modelClass: "UppyForm",
                                          modelId: "snapshot-file",
                                          collection: "thumbnail",
                                          endpoint: _vm.endpoint,
                                          disabled: _vm.filesFetching
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        loading:
                                                          _vm.filesFetching
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$store.dispatch(
                                                            "snapshotStore/fetchCurrentFiles",
                                                            {
                                                              snid:
                                                                _vm.$route
                                                                  .params.snid,
                                                              setFetchingStatus: true
                                                            }
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      [_vm._v("refresh")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2852463922
                                      )
                                    },
                                    [_c("span", [_vm._v("Refresh Files")])]
                                  ),
                                  _vm.isDevelopment === true
                                    ? _c("CreateFolder", {
                                        attrs: {
                                          disabled: _vm.filesFetching,
                                          path: _vm.endpoint
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "d-inline",
                                      attrs: {
                                        disabled:
                                          _vm.filesFetching ||
                                          !_vm.currentFiles.length,
                                        color: "primary",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.expand = !_vm.expand
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("search")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "span",
                            { staticClass: "primary--text font-weight-bold" },
                            [_vm._v("Files")]
                          )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _vm.filesFetching === false &&
                      (_vm.currentFiles.length || _vm.pathArray.length > 1) &&
                      _vm.currentSnapshotHasFileSystemPrefix
                        ? _c(
                            "v-container",
                            {
                              staticClass: "pt-0",
                              attrs: { width: "50%", fluid: "" }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-snackbar",
                                    {
                                      attrs: {
                                        timeout: _vm.snackbarTimeout,
                                        color: "info"
                                      },
                                      model: {
                                        value: _vm.stageSuggestionSnackbar,
                                        callback: function($$v) {
                                          _vm.stageSuggestionSnackbar = $$v
                                        },
                                        expression: "stageSuggestionSnackbar"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { dark: "" }
                                        },
                                        [_vm._v("info")]
                                      ),
                                      _vm._v(
                                        "Add uploaded files to stage to share with others? "
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "white",
                                            outlined: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addToStaging(
                                                _vm.lastUploadedFiles,
                                                "uploaded"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Stage")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "white", text: "" },
                                          on: { click: _vm.discardUploaded }
                                        },
                                        [_vm._v("Discard")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-breadcrumbs", {
                                staticClass: "ma-0 pb-0",
                                attrs: { large: "", items: _vm.pathArray },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(props) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.$store.dispatch(
                                                    "snapshotStore/setTreeLevel",
                                                    {
                                                      snid:
                                                        _vm.$route.params.snid,
                                                      level:
                                                        props.item.index + 1
                                                    }
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              props.item.index > 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold primary--text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(props.item.path)
                                                      )
                                                    ]
                                                  )
                                                : _vm.pathArray.length === 1
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("folder")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [_vm._v("mdi-folder-open")]
                                                  )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "divider",
                                      fn: function() {
                                        return [
                                          _c("v-icon", [_vm._v("double_arrow")])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1765331188
                                )
                              }),
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.expand,
                                    expression: "expand"
                                  }
                                ],
                                attrs: {
                                  autofocus: "",
                                  label: "Filter files..."
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _vm.filesFetching === false &&
                          (_vm.currentFiles.length ||
                            _vm.pathArray.length > 1) &&
                          _vm.currentSnapshotHasFileSystemPrefix
                            ? _c("v-divider")
                            : _vm._e(),
                          _vm.filesFetching
                            ? _c(
                                "div",
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: { type: "table" }
                                  })
                                ],
                                1
                              )
                            : !_vm.currentSnapshotHasFileSystemPrefix
                            ? _c(
                                "v-banner",
                                { attrs: { "single-line": "" } },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "60"
                                      },
                                      slot: "icon"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "x-large": "",
                                            icon: "info",
                                            color: "info"
                                          }
                                        },
                                        [_vm._v(" info ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold primary--text"
                                        },
                                        [_vm._v("Cold snapshot")]
                                      ),
                                      _vm.isInstanceEditor
                                        ? _c(
                                            "div",
                                            [
                                              _vm._v(
                                                " This snapshot is in cold storage, to access its files please "
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    loading:
                                                      _vm.restoringSnapshot,
                                                    text: "",
                                                    outlined: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.restoreSnapshotToCurrentState
                                                  }
                                                },
                                                [_vm._v("restore")]
                                              ),
                                              _vm._v(
                                                " it to the current state "
                                              )
                                            ],
                                            1
                                          )
                                        : _c("p", [
                                            _vm._v(
                                              " This snapshot is in cold storage, its files cannot be accessed directly. Please check the files of a more recent snapshot not yet in cold storage, or reach out to support for retrieval. "
                                            )
                                          ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm.pathArray.length === 1 &&
                              !_vm.currentFiles.length
                            ? _c(
                                "v-banner",
                                { attrs: { "single-line": "" } },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "60"
                                      },
                                      slot: "icon"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "x-large": "",
                                            icon: "info",
                                            color: "info"
                                          }
                                        },
                                        [_vm._v(" info ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold primary--text"
                                        },
                                        [_vm._v("No files found")]
                                      ),
                                      _c("p", [
                                        _vm._v(" Currently you don't have "),
                                        _vm.folderTypeSelected ===
                                        _vm.folderTypesLabels.WORKSPACE_FILES
                                          ? _c("span", [_vm._v("workspace")])
                                          : _vm.folderTypeSelected ===
                                            _vm.folderTypesLabels.PERSONAL_FILES
                                          ? _c("span", [_vm._v("personal")])
                                          : _vm._e(),
                                        _vm._v(" files. "),
                                        _vm.isDevelopment === true
                                          ? _c(
                                              "span",
                                              [
                                                _vm._v(
                                                  " Consider adding new files via the "
                                                ),
                                                _c("v-icon", [
                                                  _vm._v("upload")
                                                ]),
                                                _vm._v(" icon above")
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" or you can also "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "https://docs.nuvolos.cloud/getting-started/work-with-files/dropbox-synchronization",
                                              target: "_blank"
                                            }
                                          },
                                          [_vm._v("set up Dropbox integration")]
                                        ),
                                        _vm._v(". ")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("v-data-table", {
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.getTableData(
                                    _vm.filesFetching,
                                    _vm.currentFiles
                                  ),
                                  "show-select": _vm.currentFiles.length > 0,
                                  "items-per-page": _vm.itemsPerPage,
                                  "footer-props": {
                                    showFirstLastPage: false,
                                    itemsPerPageOptions:
                                      _vm.itemsPerPageOptions,
                                    prevIcon: "mdi-arrow-left-circle",
                                    nextIcon: "mdi-arrow-right-circle"
                                  },
                                  "item-key": "fid",
                                  search: _vm.search
                                },
                                on: {
                                  "update:itemsPerPage": function($event) {
                                    _vm.itemsPerPage = $event
                                  },
                                  "update:items-per-page": function($event) {
                                    _vm.itemsPerPage = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "top",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex align-center"
                                            },
                                            [
                                              _c("v-switch", {
                                                staticClass: "pr-3 pl-3 mb-0",
                                                attrs: {
                                                  label: "Hidden Files"
                                                },
                                                model: {
                                                  value: _vm.showHidden,
                                                  callback: function($$v) {
                                                    _vm.showHidden = $$v
                                                  },
                                                  expression: "showHidden"
                                                }
                                              }),
                                              _c("v-spacer"),
                                              _vm.selected.length > 0
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        depressed: "",
                                                        text: "",
                                                        outlined: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addToStaging(
                                                            _vm.selected,
                                                            "selected"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("share")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "subheader"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "stage selected"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.currentFiles.length > 0 &&
                                              _vm.isDevelopment === true
                                                ? _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "offset-y": "",
                                                        bottom: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-badge",
                                                                  {
                                                                    attrs: {
                                                                      color: _vm
                                                                        .selected
                                                                        .length
                                                                        ? "secondary"
                                                                        : "transparent",
                                                                      content:
                                                                        _vm
                                                                          .selected
                                                                          .length,
                                                                      overlap:
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "caption",
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            icon:
                                                                              "",
                                                                            dark:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "more_vert"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1781421648
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        {
                                                          attrs: {
                                                            dense: "",
                                                            nav: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "DeleteButton",
                                                                    {
                                                                      attrs: {
                                                                        selected:
                                                                          _vm.selected,
                                                                        objectType:
                                                                          "files",
                                                                        dispatchFunction: function() {
                                                                          _vm.$store.dispatch(
                                                                            "snapshotStore/fetchCurrentFiles",
                                                                            {
                                                                              snid:
                                                                                _vm
                                                                                  .$route
                                                                                  .params
                                                                                  .snid,
                                                                              setFetchingStatus: true
                                                                            }
                                                                          )
                                                                        }
                                                                      },
                                                                      on: {
                                                                        finished: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.emptySelected(
                                                                            $event.value
                                                                          )
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .selected
                                                                          .length,
                                                                        color:
                                                                          "primary",
                                                                        text:
                                                                          "",
                                                                        block:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.addToMoveCopyList(
                                                                            _vm.selected,
                                                                            "move",
                                                                            true
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center justify-start",
                                                                          staticStyle: {
                                                                            width:
                                                                              "100%"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-folder-move"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " move "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .selected
                                                                          .length,
                                                                        color:
                                                                          "primary",
                                                                        text:
                                                                          "",
                                                                        block:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.addToMoveCopyList(
                                                                            _vm.selected,
                                                                            "copy",
                                                                            true
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center justify-start",
                                                                          staticStyle: {
                                                                            width:
                                                                              "100%"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              attrs: {
                                                                                small:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "content_copy"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " copy "
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "body",
                                      fn: function(ref) {
                                        var items = ref.items
                                        return [
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(items, function(item) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    key: item.fid,
                                                    attrs: {
                                                      search: _vm.search
                                                    },
                                                    on: {
                                                      mouseover: function(
                                                        $event
                                                      ) {
                                                        return _vm.selectItem(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("v-checkbox", {
                                                          staticStyle: {
                                                            margin: "0px",
                                                            padding: "0px"
                                                          },
                                                          attrs: {
                                                            multiple: "",
                                                            value: item,
                                                            "hide-details": "",
                                                            flat: ""
                                                          },
                                                          model: {
                                                            value: _vm.selected,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.selected = $$v
                                                            },
                                                            expression:
                                                              "selected"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("td", [
                                                      item.type === "folder"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-3"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "folder"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "secondary--text subtitle-2",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openDirectory(
                                                                        item.short_id
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.short_id
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center"
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-3"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.fileIcon(
                                                                        item.type,
                                                                        item.short_id
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    "close-on-content-click": false,
                                                                    bottom: "",
                                                                    "offset-x":
                                                                      "",
                                                                    origin:
                                                                      "center center",
                                                                    transition:
                                                                      "scale-transition",
                                                                    "max-width":
                                                                      "200"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "a",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "secondary--text subtitle-2",
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.fetchFileDownloadToken(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.short_id
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        nav: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      item.short_id &&
                                                                      item.short_id
                                                                        .toLowerCase()
                                                                        .endsWith(
                                                                          ".pdf"
                                                                        )
                                                                        ? _c(
                                                                            "v-list-item",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        text:
                                                                                          "",
                                                                                        loading:
                                                                                          _vm.downloadingFileForPreview,
                                                                                        block:
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.previewFile(
                                                                                            item
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex align-center justify-start",
                                                                                          staticStyle: {
                                                                                            width:
                                                                                              "100%"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1",
                                                                                              attrs: {
                                                                                                small:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-eye"
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " view "
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "v-list-item",
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    disabled:
                                                                                      _vm.currentFileDownloadLink ===
                                                                                        null ||
                                                                                      _vm.fetchingFileToken,
                                                                                    href:
                                                                                      _vm.currentFileDownloadLink,
                                                                                    download:
                                                                                      item.type ===
                                                                                      "folder"
                                                                                        ? item.short_id +
                                                                                          ".zip"
                                                                                        : item.short_id,
                                                                                    color:
                                                                                      "primary",
                                                                                    text:
                                                                                      "",
                                                                                    block:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        width:
                                                                                          "100%"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mr-1",
                                                                                          attrs: {
                                                                                            small:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-download"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " download "
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm.isDevelopment ===
                                                                        true &&
                                                                      _vm.isEditableFile(
                                                                        item
                                                                      ) === true
                                                                        ? _c(
                                                                            "v-list-item",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _c(
                                                                                    "TheSnapshotFileEditor",
                                                                                    {
                                                                                      attrs: {
                                                                                        endpoint:
                                                                                          _vm.endpoint,
                                                                                        fileData: item,
                                                                                        language: _vm.fileLanguage(
                                                                                          item
                                                                                        ),
                                                                                        isBlock: true
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                    ]),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("FileStatus", {
                                                          attrs: {
                                                            fileData: item
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("td", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-end grey--text"
                                                        },
                                                        [
                                                          item.type !== "folder"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.humanFileSize(
                                                                      item.size,
                                                                      true
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      item === _vm.selectedItem
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp
                                                                ? _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        small:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        text: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.addToStaging(
                                                                            [
                                                                              item
                                                                            ],
                                                                            "selected"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1",
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "share"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        "stage "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    "close-on-click": false,
                                                                    "close-on-content-click": false,
                                                                    left: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                {
                                                                                  attrs: {
                                                                                    text:
                                                                                      "",
                                                                                    color:
                                                                                      "primary",
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.fetchFileDownloadToken(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "more_horiz"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    [
                                                                      _c(
                                                                        "v-card-text",
                                                                        {
                                                                          staticClass:
                                                                            "ma-0 pt-1 pb-1 pl-0 pr-0"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list",
                                                                            {
                                                                              attrs: {
                                                                                nav:
                                                                                  "",
                                                                                dense:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            disabled:
                                                                                              _vm.currentFileDownloadLink ===
                                                                                                null ||
                                                                                              _vm.fetchingFileToken,
                                                                                            href:
                                                                                              _vm.currentFileDownloadLink,
                                                                                            download:
                                                                                              item.type ===
                                                                                              "folder"
                                                                                                ? item.short_id +
                                                                                                  ".zip"
                                                                                                : item.short_id,
                                                                                            color:
                                                                                              "primary",
                                                                                            text:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-1",
                                                                                              attrs: {
                                                                                                small:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-download"
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " download "
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm.isDevelopment ===
                                                                                true &&
                                                                              item.short_id &&
                                                                              item.short_id
                                                                                .toLowerCase()
                                                                                .endsWith(
                                                                                  ".pdf"
                                                                                )
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "primary",
                                                                                                text:
                                                                                                  "",
                                                                                                loading:
                                                                                                  _vm.downloadingFileForPreview,
                                                                                                block:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.previewFile(
                                                                                                    item
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex align-center justify-start",
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "100%"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-1",
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-eye"
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " view "
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isDevelopment ===
                                                                              true
                                                                                ? _c(
                                                                                    "v-divider"
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isDevelopment ===
                                                                              true
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 primary--text"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "RenameButton",
                                                                                            {
                                                                                              attrs: {
                                                                                                selected: [
                                                                                                  item
                                                                                                ],
                                                                                                objectType:
                                                                                                  "file",
                                                                                                dispatchFunction: function() {
                                                                                                  _vm.$store.dispatch(
                                                                                                    "snapshotStore/fetchCurrentFiles",
                                                                                                    {
                                                                                                      snid:
                                                                                                        _vm
                                                                                                          .$route
                                                                                                          .params
                                                                                                          .snid,
                                                                                                      setFetchingStatus: true
                                                                                                    }
                                                                                                  )
                                                                                                }
                                                                                              },
                                                                                              on: {
                                                                                                finished: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.emptySelected(
                                                                                                    $event.value
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .smAndDown &&
                                                                              _vm.isDevelopment ===
                                                                                true
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 primary--text"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "primary",
                                                                                                text:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.addToStaging(
                                                                                                    [
                                                                                                      item
                                                                                                    ],
                                                                                                    "selected"
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1",
                                                                                                  attrs: {
                                                                                                    small:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "mdi-playlist-plus"
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " stage "
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isDevelopment ===
                                                                              true
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 primary--text"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "primary",
                                                                                                text:
                                                                                                  "",
                                                                                                block:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.addToMoveCopyList(
                                                                                                    [
                                                                                                      item
                                                                                                    ],
                                                                                                    "move"
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex align-center justify-start",
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "100%"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-1",
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-folder-move"
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " move "
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isDevelopment ===
                                                                                true &&
                                                                              _vm.isEditableFile(
                                                                                item
                                                                              ) ===
                                                                                true
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        [
                                                                                          _c(
                                                                                            "TheSnapshotFileEditor",
                                                                                            {
                                                                                              attrs: {
                                                                                                endpoint:
                                                                                                  _vm.endpoint,
                                                                                                fileData: item,
                                                                                                language: _vm.fileLanguage(
                                                                                                  item
                                                                                                ),
                                                                                                isBlock: true
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isDevelopment ===
                                                                              true
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 primary--text"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "primary",
                                                                                                text:
                                                                                                  "",
                                                                                                block:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.addToMoveCopyList(
                                                                                                    [
                                                                                                      item
                                                                                                    ],
                                                                                                    "copy"
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex align-center justify-start",
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "100%"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-1",
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "content_copy"
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " copy "
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              item.type !==
                                                                                "folder" &&
                                                                              _vm.isDevelopment ===
                                                                                true
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 primary--text"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs: {
                                                                                                block:
                                                                                                  "",
                                                                                                color:
                                                                                                  "primary",
                                                                                                text:
                                                                                                  ""
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.duplicateFile(
                                                                                                    item
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex align-center justify-start",
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "100%"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-1",
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          ""
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-content-duplicate"
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " duplicate "
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isDevelopment ===
                                                                              true
                                                                                ? _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-title",
                                                                                        {
                                                                                          staticClass:
                                                                                            "subtitle-2 primary--text"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "DeleteButton",
                                                                                            {
                                                                                              attrs: {
                                                                                                selected: [
                                                                                                  item
                                                                                                ],
                                                                                                objectType:
                                                                                                  "files",
                                                                                                dispatchFunction: function() {
                                                                                                  _vm.$store.dispatch(
                                                                                                    "snapshotStore/fetchCurrentFiles",
                                                                                                    {
                                                                                                      snid:
                                                                                                        _vm
                                                                                                          .$route
                                                                                                          .params
                                                                                                          .snid,
                                                                                                      setFetchingStatus: true
                                                                                                    }
                                                                                                  )
                                                                                                }
                                                                                              },
                                                                                              on: {
                                                                                                finished: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.emptySelected(
                                                                                                    $event.value
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e()
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ])
                                                  ]
                                                )
                                              }),
                                              !items.length
                                                ? _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "5" }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-3 mb-3"
                                                          },
                                                          [
                                                            _vm.filesFetching ===
                                                            true
                                                              ? _c(
                                                                  "v-alert",
                                                                  {
                                                                    attrs: {
                                                                      dense: "",
                                                                      type:
                                                                        "info",
                                                                      text: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Loading the list of files, please wait..."
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm.pathArray
                                                                  .length > 1
                                                              ? _c(
                                                                  "v-alert",
                                                                  {
                                                                    attrs: {
                                                                      dense: "",
                                                                      type:
                                                                        "warning",
                                                                      text: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "No files."
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm.filesFetching ===
                                                                false
                                                              ? _c(
                                                                  "v-alert",
                                                                  {
                                                                    attrs: {
                                                                      dense: "",
                                                                      type:
                                                                        "warning",
                                                                      text: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Currently you don't have "
                                                                    ),
                                                                    _vm.folderTypeSelected ===
                                                                    _vm
                                                                      .folderTypesLabels
                                                                      .WORKSPACE_FILES
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "workspace"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm.folderTypeSelected ===
                                                                        _vm
                                                                          .folderTypesLabels
                                                                          .PERSONAL_FILES
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "personal"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      " files. Use the "
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "font-weight-bold"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Hidden Files"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " switch above to display any hidden files. "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1828532529
                                ),
                                model: {
                                  value: _vm.selected,
                                  callback: function($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.compiledMarkdown.length
                ? _c("v-container", [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "mt-3",
                            staticStyle: { "max-width": "1050px" },
                            attrs: { outlined: "" }
                          },
                          [
                            _c(
                              "v-card-title",
                              [
                                _c("v-icon", { staticClass: "mr-1" }, [
                                  _vm._v("mdi-markdown")
                                ]),
                                _vm._v("README.md")
                              ],
                              1
                            ),
                            _c("v-card-text", [
                              _c("div", {
                                staticClass: "markdown-body",
                                domProps: {
                                  innerHTML: _vm._s(_vm.compiledMarkdown)
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _c("PdfViewer", {
            attrs: {
              pdfUrl: _vm.previewBlobUrl,
              longId: _vm.previewLongId,
              previewDialog: _vm.previewDialog
            },
            on: {
              "update:previewDialog": function($event) {
                _vm.previewDialog = $event
              },
              "update:preview-dialog": function($event) {
                _vm.previewDialog = $event
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }