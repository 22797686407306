<template>
    <div style="height:100%">
        <v-container fluid v-if="!previewDialog">
            <v-banner class="mb-5" v-if="currentSpaceType === spaceTypes.VENDOR_SPACE && currentTables.length > 0" single-line>
                <v-avatar slot="icon" color="white" size="30"> <v-icon color="info">info</v-icon> </v-avatar>This dataset contains dataset tables.
                <template v-slot:actions>
                    <v-btn
                        :to="{
                            name: 'snapshot-tables',
                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                        }"
                        text
                        color="secondary"
                        >Browse tables</v-btn
                    >
                    <v-btn href="https://docs.nuvolos.cloud/actions/work-with-data-in-nuvolos" target="_blank" text color="secondary"
                        >How to handle data in Nuvolos?</v-btn
                    >
                </template>
            </v-banner>
            <v-card flat>
                <v-card-title>
                    <TheSnapshotMoveAndCopyFiles
                        :selectedFiles="filesToMoveOrCopy"
                        :operationType="copyOrMoveOperation"
                        :folderTypeSelected="folderTypeSelected"
                        @clearCopyAndMoveFiles="clearCopyAndMoveFiles()"
                        @removeFileFromMoveCopyList="removeFileFromMoveCopyList($event.fid)"
                    />
                    <div v-if="currentSnapshotHasFileSystemPrefix" style="width:100%" class="d-flex justify-space-between align-space-between">
                        <div class="d-inline">
                            <span class="primary--text font-weight-bold">Files</span>
                            <v-menu :disabled="filesFetching" offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn small text outlined color="primary" class="caption font-weight-bold ml-2" v-on="on">
                                        {{ folderTypeSelected === folderTypesLabels.WORKSPACE_FILES ? 'WORKSPACE' : 'PERSONAL' }}
                                        <v-icon small>keyboard_arrow_down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list nav dense>
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-list-item v-on="on" @click="changeFileType(folderTypesLabels.WORKSPACE_FILES)">
                                                <v-list-item-title>Workspace</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        <span>Public files visible to all instance editors/viewers.</span>
                                    </v-tooltip>
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-list-item v-on="on" @click="changeFileType(folderTypesLabels.PERSONAL_FILES)">
                                                <v-list-item-title>Personal</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                        <span>Files visible to you only.</span>
                                    </v-tooltip>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="d-flex justify-center align-center">
                            <div v-if="quotaPercentageUsage !== null">
                                <v-chip class="mr-1" :color="quotaPercentageUsage <= 90 ? 'primary' : 'error'" outlined small>
                                    <span class="caption font-weight-medium">
                                        {{ `${quotaPercentageUsage}% of ${parseFloat((snapshotQuota.bytes_quota / 1024 ** 3).toFixed(2))}GB` }}
                                        used
                                    </span>
                                </v-chip>
                            </div>
                            <div v-show="isDevelopment === true">
                                <UppyUpload
                                    modelClass="UppyForm"
                                    modelId="snapshot-file"
                                    collection="thumbnail"
                                    :endpoint="endpoint"
                                    :disabled="filesFetching"
                                    class="d-inline"
                                />
                            </div>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        @click="$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: $route.params.snid, setFetchingStatus: true })"
                                        icon
                                        v-on="on"
                                        :loading="filesFetching"
                                    >
                                        <v-icon color="primary">refresh</v-icon>
                                    </v-btn>
                                </template>
                                <span>Refresh Files</span>
                            </v-tooltip>
                            <CreateFolder v-if="isDevelopment === true" :disabled="filesFetching" :path="endpoint" />

                            <v-btn :disabled="filesFetching || !currentFiles.length" class="d-inline" color="primary" @click="expand = !expand" icon>
                                <v-icon>search</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <span v-else class="primary--text font-weight-bold">Files</span>
                </v-card-title>
                <v-card-text>
                    <v-container
                        v-if="filesFetching === false && (currentFiles.length || pathArray.length > 1) && currentSnapshotHasFileSystemPrefix"
                        width="50%"
                        fluid
                        class="pt-0"
                    >
                        <div>
                            <v-snackbar v-model="stageSuggestionSnackbar" :timeout="snackbarTimeout" color="info">
                                <v-icon class="mr-1" dark>info</v-icon>Add uploaded files to stage to share with others?
                                <v-btn color="white" outlined @click="addToStaging(lastUploadedFiles, 'uploaded')">Stage</v-btn>
                                <v-btn color="white" text @click="discardUploaded">Discard</v-btn>
                            </v-snackbar>
                        </div>
                        <v-breadcrumbs large :items="pathArray" class="ma-0 pb-0">
                            <template v-slot:item="props">
                                <a @click="$store.dispatch('snapshotStore/setTreeLevel', { snid: $route.params.snid, level: props.item.index + 1 })">
                                    <span class="font-weight-bold primary--text" v-if="props.item.index > 0">{{ props.item.path }}</span>
                                    <v-icon v-else-if="pathArray.length === 1" color="primary">folder</v-icon>
                                    <v-icon v-else color="primary">mdi-folder-open</v-icon>
                                </a>
                            </template>
                            <template v-slot:divider>
                                <v-icon>double_arrow</v-icon>
                            </template>
                        </v-breadcrumbs>
                        <v-text-field autofocus v-show="expand" label="Filter files..." v-model="search"></v-text-field>
                    </v-container>
                    <div>
                        <v-divider
                            v-if="filesFetching === false && (currentFiles.length || pathArray.length > 1) && currentSnapshotHasFileSystemPrefix"
                        ></v-divider>
                        <div v-if="filesFetching">
                            <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                        </div>
                        <v-banner v-else-if="!currentSnapshotHasFileSystemPrefix" single-line>
                            <v-avatar slot="icon" color="white" size="60">
                                <v-icon x-large icon="info" color="info">
                                    info
                                </v-icon>
                            </v-avatar>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold primary--text">Cold snapshot</span>
                                <div v-if="isInstanceEditor">
                                    This snapshot is in cold storage, to access its files please
                                    <v-btn :loading="restoringSnapshot" @click="restoreSnapshotToCurrentState" text outlined small>restore</v-btn> it to the
                                    current state
                                </div>
                                <p v-else>
                                    This snapshot is in cold storage, its files cannot be accessed directly. Please check the files of a more recent snapshot
                                    not yet in cold storage, or reach out to support for retrieval.
                                </p>
                            </div>
                        </v-banner>
                        <v-banner v-else-if="pathArray.length === 1 && !currentFiles.length" single-line>
                            <v-avatar slot="icon" color="white" size="60">
                                <v-icon x-large icon="info" color="info">
                                    info
                                </v-icon>
                            </v-avatar>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold primary--text">No files found</span>
                                <p>
                                    Currently you don't have
                                    <span v-if="folderTypeSelected === folderTypesLabels.WORKSPACE_FILES">workspace</span>
                                    <span v-else-if="folderTypeSelected === folderTypesLabels.PERSONAL_FILES">personal</span>
                                    files.
                                    <span v-if="isDevelopment === true"> Consider adding new files via the <v-icon>upload</v-icon> icon above</span>
                                    or you can also
                                    <a href="https://docs.nuvolos.cloud/getting-started/work-with-files/dropbox-synchronization" target="_blank"
                                        >set up Dropbox integration</a
                                    >.
                                </p>
                            </div>
                        </v-banner>
                        <v-data-table
                            :headers="headers"
                            :items="getTableData(filesFetching, currentFiles)"
                            :show-select="currentFiles.length > 0"
                            v-model="selected"
                            :items-per-page.sync="itemsPerPage"
                            :footer-props="{
                                showFirstLastPage: false,
                                itemsPerPageOptions: itemsPerPageOptions,
                                prevIcon: 'mdi-arrow-left-circle',
                                nextIcon: 'mdi-arrow-right-circle'
                            }"
                            item-key="fid"
                            :search="search"
                            v-else
                        >
                            <template v-slot:top>
                                <div class="d-flex align-center">
                                    <v-switch v-model="showHidden" label="Hidden Files" class="pr-3 pl-3 mb-0"></v-switch>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="addToStaging(selected, 'selected')" v-if="selected.length > 0" color="primary" depressed text outlined>
                                        <v-icon class="mr-1" small>share</v-icon>
                                        <span class="subheader">stage selected</span>
                                    </v-btn>
                                    <v-menu v-if="currentFiles.length > 0 && isDevelopment === true" :close-on-content-click="false" offset-y bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge :color="selected.length ? 'secondary' : 'transparent'" :content="selected.length" overlap>
                                                <v-btn v-on="on" color="primary" icon dark class="caption">
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </v-badge>
                                        </template>
                                        <v-list dense nav>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <DeleteButton
                                                        :selected="selected"
                                                        objectType="files"
                                                        @finished="emptySelected($event.value)"
                                                        :dispatchFunction="
                                                            () => {
                                                                $store.dispatch('snapshotStore/fetchCurrentFiles', {
                                                                    snid: $route.params.snid,
                                                                    setFetchingStatus: true
                                                                })
                                                            }
                                                        "
                                                    ></DeleteButton>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <v-btn
                                                        @click="addToMoveCopyList(selected, 'move', true)"
                                                        :disabled="!selected.length"
                                                        color="primary"
                                                        text
                                                        block
                                                    >
                                                        <div style="width:100%" class="d-flex align-center justify-start">
                                                            <v-icon class="mr-1" small>mdi-folder-move</v-icon>
                                                            move
                                                        </div>
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <v-btn
                                                        @click="addToMoveCopyList(selected, 'copy', true)"
                                                        :disabled="!selected.length"
                                                        color="primary"
                                                        text
                                                        block
                                                    >
                                                        <div style="width:100%" class="d-flex align-center justify-start">
                                                            <v-icon class="mr-1" small>content_copy</v-icon>
                                                            copy
                                                        </div>
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </template>
                            <template v-slot:body="{ items }">
                                <tbody>
                                    <tr v-for="item in items" :key="item.fid" :search="search" @mouseover="selectItem(item)">
                                        <td>
                                            <v-checkbox multiple :value="item" v-model="selected" style="margin:0px;padding:0px" hide-details flat />
                                        </td>
                                        <td>
                                            <div v-if="item.type === 'folder'" class="d-flex align-center">
                                                <v-icon class="mr-3">folder</v-icon>
                                                <a class="secondary--text subtitle-2" @click="openDirectory(item.short_id)">{{ item.short_id }}</a>
                                            </div>
                                            <div v-else class="d-flex align-center">
                                                <v-icon class="mr-3">{{ fileIcon(item.type, item.short_id) }}</v-icon>
                                                <v-menu
                                                    :close-on-content-click="false"
                                                    bottom
                                                    offset-x
                                                    origin="center center"
                                                    transition="scale-transition"
                                                    max-width="200"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <a v-on="on" @click="fetchFileDownloadToken(item)" class="secondary--text subtitle-2">{{
                                                            item.short_id
                                                        }}</a>
                                                    </template>

                                                    <v-list dense nav>
                                                        <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.pdf')">
                                                            <v-list-item-title>
                                                                <v-btn
                                                                    @click="previewFile(item)"
                                                                    color="primary"
                                                                    text
                                                                    :loading="downloadingFileForPreview"
                                                                    block
                                                                >
                                                                    <div style="width:100%" class="d-flex align-center justify-start">
                                                                        <v-icon class="mr-1" small>mdi-eye</v-icon>
                                                                        view
                                                                    </div>
                                                                </v-btn>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item>
                                                            <v-list-item-title>
                                                                <v-btn
                                                                    :disabled="currentFileDownloadLink === null || fetchingFileToken"
                                                                    :href="currentFileDownloadLink"
                                                                    :download="item.type === 'folder' ? `${item.short_id}.zip` : item.short_id"
                                                                    color="primary"
                                                                    text
                                                                    block
                                                                >
                                                                    <div style="width:100%">
                                                                        <v-icon class="mr-1" small>mdi-download</v-icon>
                                                                        download
                                                                    </div>
                                                                </v-btn>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item v-if="isDevelopment === true && isEditableFile(item) === true">
                                                            <v-list-item-title>
                                                                <TheSnapshotFileEditor
                                                                    :endpoint="endpoint"
                                                                    :fileData="item"
                                                                    :language="fileLanguage(item)"
                                                                    :isBlock="true"
                                                                ></TheSnapshotFileEditor>
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </td>
                                        <td>
                                            <FileStatus :fileData="item"></FileStatus>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-end grey--text">
                                                <span v-if="item.type !== 'folder'">{{ humanFileSize(item.size, true) }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="item === selectedItem">
                                                <v-btn
                                                    @click="addToStaging([item], 'selected')"
                                                    color="primary"
                                                    class="mr-2"
                                                    small
                                                    outlined
                                                    text
                                                    v-if="$vuetify.breakpoint.mdAndUp"
                                                >
                                                    <v-icon class="mr-1" small>share</v-icon>stage
                                                </v-btn>
                                                <v-menu :close-on-click="false" :close-on-content-click="false" left>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" text color="primary" @click="fetchFileDownloadToken(item)" small>
                                                            <v-icon>more_horiz</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-text class="ma-0 pt-1 pb-1 pl-0 pr-0">
                                                            <v-list nav dense>
                                                                <v-list-item>
                                                                    <v-list-item-title>
                                                                        <v-btn
                                                                            :disabled="currentFileDownloadLink === null || fetchingFileToken"
                                                                            :href="currentFileDownloadLink"
                                                                            :download="item.type === 'folder' ? `${item.short_id}.zip` : item.short_id"
                                                                            color="primary"
                                                                            text
                                                                        >
                                                                            <v-icon class="mr-1" small>mdi-download</v-icon>
                                                                            download
                                                                        </v-btn>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item
                                                                    v-if="
                                                                        isDevelopment === true && item.short_id && item.short_id.toLowerCase().endsWith('.pdf')
                                                                    "
                                                                >
                                                                    <v-list-item-title>
                                                                        <v-btn
                                                                            @click="previewFile(item)"
                                                                            color="primary"
                                                                            text
                                                                            :loading="downloadingFileForPreview"
                                                                            block
                                                                        >
                                                                            <div style="width:100%" class="d-flex align-center justify-start">
                                                                                <v-icon class="mr-1" small>mdi-eye</v-icon>
                                                                                view
                                                                            </div>
                                                                        </v-btn>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-divider v-if="isDevelopment === true" />
                                                                <v-list-item v-if="isDevelopment === true">
                                                                    <v-list-item-title class="subtitle-2 primary--text">
                                                                        <RenameButton
                                                                            :selected="[item]"
                                                                            objectType="file"
                                                                            @finished="emptySelected($event.value)"
                                                                            :dispatchFunction="
                                                                                () => {
                                                                                    $store.dispatch('snapshotStore/fetchCurrentFiles', {
                                                                                        snid: $route.params.snid,
                                                                                        setFetchingStatus: true
                                                                                    })
                                                                                }
                                                                            "
                                                                        ></RenameButton>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="$vuetify.breakpoint.smAndDown && isDevelopment === true">
                                                                    <v-list-item-title class="subtitle-2 primary--text">
                                                                        <v-btn @click="addToStaging([item], 'selected')" color="primary" text>
                                                                            <v-icon class="mr-1" small>mdi-playlist-plus</v-icon>
                                                                            stage
                                                                        </v-btn>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="isDevelopment === true">
                                                                    <v-list-item-title class="subtitle-2 primary--text">
                                                                        <v-btn @click="addToMoveCopyList([item], 'move')" color="primary" text block>
                                                                            <div style="width:100%" class="d-flex align-center justify-start">
                                                                                <v-icon class="mr-1" small>mdi-folder-move</v-icon>
                                                                                move
                                                                            </div>
                                                                        </v-btn>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="isDevelopment === true && isEditableFile(item) === true">
                                                                    <v-list-item-title>
                                                                        <TheSnapshotFileEditor
                                                                            :endpoint="endpoint"
                                                                            :fileData="item"
                                                                            :language="fileLanguage(item)"
                                                                            :isBlock="true"
                                                                        ></TheSnapshotFileEditor>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="isDevelopment === true">
                                                                    <v-list-item-title class="subtitle-2 primary--text">
                                                                        <v-btn @click="addToMoveCopyList([item], 'copy')" color="primary" text block>
                                                                            <div style="width:100%" class="d-flex align-center justify-start">
                                                                                <v-icon class="mr-1" small>content_copy</v-icon>
                                                                                copy
                                                                            </div>
                                                                        </v-btn>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="item.type !== 'folder' && isDevelopment === true">
                                                                    <v-list-item-title class="subtitle-2 primary--text">
                                                                        <v-btn block @click="duplicateFile(item)" color="primary" text>
                                                                            <div style="width:100%" class="d-flex align-center justify-start">
                                                                                <v-icon class="mr-1" small>mdi-content-duplicate</v-icon>
                                                                                duplicate
                                                                            </div>
                                                                        </v-btn>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="isDevelopment === true">
                                                                    <v-list-item-title class="subtitle-2 primary--text">
                                                                        <DeleteButton
                                                                            :selected="[item]"
                                                                            objectType="files"
                                                                            @finished="emptySelected($event.value)"
                                                                            :dispatchFunction="
                                                                                () => {
                                                                                    $store.dispatch('snapshotStore/fetchCurrentFiles', {
                                                                                        snid: $route.params.snid,
                                                                                        setFetchingStatus: true
                                                                                    })
                                                                                }
                                                                            "
                                                                        ></DeleteButton>
                                                                    </v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-menu>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="!items.length">
                                        <td colspan="5">
                                            <div class="mt-3 mb-3">
                                                <v-alert dense type="info" text v-if="filesFetching === true"
                                                    >Loading the list of files, please wait...</v-alert
                                                >
                                                <v-alert dense type="warning" text v-else-if="pathArray.length > 1">No files.</v-alert>
                                                <v-alert dense type="warning" text v-else-if="filesFetching === false">
                                                    Currently you don't have
                                                    <span v-if="folderTypeSelected === folderTypesLabels.WORKSPACE_FILES">workspace</span>
                                                    <span v-else-if="folderTypeSelected === folderTypesLabels.PERSONAL_FILES">personal</span>
                                                    files. Use the <span class="font-weight-bold">Hidden Files</span> switch above to display any hidden files.
                                                </v-alert>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>
            <v-container v-if="compiledMarkdown.length">
                <div class="d-flex justify-center">
                    <v-card class="mt-3" outlined style="max-width:1050px">
                        <v-card-title> <v-icon class="mr-1">mdi-markdown</v-icon>README.md</v-card-title>
                        <v-card-text>
                            <div v-html="compiledMarkdown" class="markdown-body"></div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-container>
        </v-container>
        <PdfViewer :pdfUrl="previewBlobUrl" v-else :longId="previewLongId" :previewDialog.sync="previewDialog"></PdfViewer>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { humanFileSize } from '@/utils'
import * as marked from 'marked'
import * as dompurify from 'dompurify'
import highlight from 'highlight.js'
import 'github-markdown-css/github-markdown.css'
import 'highlight.js/styles/school-book.css'
import { enumsData } from '@/mixins/enums'
const UppyUpload = () => import('@/components/UppyUpload')
const RenameButton = () => import('../components/TheSnapshotRenameButton')
const DeleteButton = () => import('../components/TheSnapshotDeleteButton')
const FileStatus = () => import('../components/TheSnapshotFileStatus')
const PdfViewer = () => import('../components/PdfViewer')
const CreateFolder = () => import('../components/TheSnapshotCreateFolder')
const TheSnapshotMoveAndCopyFiles = () => import('../components/TheSnapshotMoveAndCopyFiles')
const TheSnapshotFileEditor = () => import('../components/TheSnapshotFileEditor')
function initialState() {
    return {
        search: '',
        headers: [
            { text: 'File', align: 'left', value: 'short_id' },
            {
                text: 'Last modified',
                value: 'last_modified_timestamp',
                width: '235px'
            },
            { text: 'Size', value: 'size', align: 'end' },
            { text: 'Actions', align: 'center', value: 'actions', width: '200px' }
        ],
        selected: [],
        filesToMoveOrCopy: [],
        folderTypeSelected: 'files',
        numFilesToDelete: 0,
        itemsPerPage: 25,
        itemsPerPageOptions: [25, 50, 100, -1],
        currentPathString: '',
        downloading: [],
        currentFilesVisible: [],
        closeDelay: 100,
        showHidden: false,
        show: false,
        readme_md_content: '',
        readmeMDLastModified: '',
        stageSuggestionSnackbar: false,
        snackbarTimeout: 10000,
        denseTable: false,
        panel: 1,
        expand: false,
        selectedItem: false,
        expandedTable: [],
        filesBatchData: [],
        previewDialog: false,
        previewBlobUrl: '',
        previewLongId: '',
        fetchingFileToken: false,
        currentFileDownloadLink: null,
        downloadingFileForPreview: false,
        copyOrMoveOperation: null,
        fileEditContent: '',
        editableFileTypes: ['text/plain', 'text/json', 'text/html'],
        editableFileExtensions: [
            'py',
            'r',
            'cpp',
            'java',
            'scala',
            'php',
            'js',
            'cfg',
            'yaml',
            'ini',
            'm',
            'sas',
            'do',
            'ado',
            'md',
            'sh',
            'txt',
            'xml',
            'sql',
            'json',
            'log',
            'hpcignore'
        ],
        editorLanguages: {
            cpp: 'cpp',
            css: 'css',
            html: 'html',
            ini: 'ini',
            java: 'java',
            js: 'javascript',
            json: 'json',
            md: 'markdown',
            php: 'php',
            py: 'python',
            r: 'r',
            scala: 'scala',
            sh: 'shell',
            sql: 'sql',
            yaml: 'yaml'
        }
    }
}
export default {
    name: 'snapshot-files',
    mixins: [enumsData],
    data: () => {
        return initialState()
    },
    computed: {
        endpoint() {
            const snid = this.$route.params.snid
            const type =
                this.$data.folderTypeSelected === this.$data.folderTypesLabels.PERSONAL_FILES
                    ? this.$store.state.snapshotStore.fileAreaType + '/' + this.$store.state.userInfo.uid
                    : this.$store.state.snapshotStore.fileAreaType
            return `/snapshots/${snid}/fs/${type}` + this.$store.state.snapshotStore.pathArray.join('/')
        },
        quotaPercentageUsage() {
            if (this.snapshotQuota) {
                return parseFloat(this.snapshotQuota.bytes_pcent) || 0
            }
            return null
        },
        currentSnapshotHasFileSystemPrefix() {
            if (this.instanceSnapshots) {
                const currentSnapshot = this.instanceSnapshots.find(snapshot => snapshot.snid.toString() === this.$route.params.snid.toString())
                return currentSnapshot && currentSnapshot.filesystem_prefix ? currentSnapshot.filesystem_prefix : null
            }
            return null
        },
        pathArray() {
            return this.$store.state.snapshotStore.pathArray.map((v, i) => {
                return { path: v, index: i }
            })
        },
        compiledMarkdown() {
            if (this.$data.readme_md_content) {
                return dompurify.sanitize(
                    marked(this.$data.readme_md_content, {
                        highlight(md) {
                            return highlight.highlightAuto(md).value
                        }
                    }),
                    {
                        ADD_TAGS: ['iframe'],
                        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
                    }
                )
            } else {
                return ''
            }
        },
        currentFiles() {
            return this.$store.state.snapshotStore.currentFiles.map(f => {
                f.isFolder = f.type === 'folder' ? 1 : 0
                return f
            })
        },
        ...mapState('snapshotStore', [
            'showUploadSnackbar',
            'stagingObjects',
            'filesFetching',
            'lastUploadedFiles',
            'currentTables',
            'currentTreeLevel',
            'restoringSnapshot',
            'snapshotQuota'
        ]),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        ...mapState(['userInfo']),
        ...mapState('instanceStore', ['instanceSnapshots']),
        ...mapGetters('instanceStore', ['isInstanceEditor'])
    },
    methods: {
        setSelectedToAll: function(evt) {
            console.log(evt)
            if (evt.value === true) {
                const fids = evt.items.map(file => file.fid)
                console.log(fids)
                this.$data.selected = fids
            } else {
                this.$data.selected = []
            }
        },
        restoreSnapshotToCurrentState: function() {
            this.$store.dispatch('snapshotStore/restoreSnapshotToCurrentState', { snid: this.$route.params.snid, createBackup: true })
        },
        resetInitialState: function() {
            Object.assign(this.$data, initialState())
        },
        humanFileSize: function(bytes, si) {
            return humanFileSize(bytes, si)
        },
        isEditableFile(fileData) {
            const fileExtension = fileData.short_id
                .toLowerCase()
                .split('.')
                .pop()
            if (
                fileData.size <= 1000000 &&
                (this.$data.editableFileTypes.includes(fileData.type) || this.$data.editableFileExtensions.includes(fileExtension))
            ) {
                return true
            } else {
                return false
            }
        },
        fileLanguage(fileData) {
            const fileExtension = fileData.short_id
                .toLowerCase()
                .split('.')
                .pop()
            const availableLanguages = Object.keys(this.editorLanguages)
            if (this.$data.editableFileExtensions.includes(fileExtension) && availableLanguages.includes(fileExtension)) {
                return this.editorLanguages[fileExtension]
            } else {
                return ''
            }
        },
        sortFilesList(files) {
            const sortedByDate = files.sort(function(a, b) {
                return new Date(b.last_modified_timestamp) - new Date(a.last_modified_timestamp)
            })
            const foldersList = sortedByDate.filter(file => file.isFolder === 1)
            const filesList = sortedByDate.filter(file => file.isFolder === 0)
            return foldersList.concat(filesList)
        },
        getTableData: function(filesFetching, currentFiles) {
            if (filesFetching) {
                return []
            } else if (this.$data.showHidden) {
                const filteredData = currentFiles.filter(f => {
                    return f.short_id !== '.git'
                })
                return this.sortFilesList(filteredData)
            } else {
                const filteredData = currentFiles.filter(f => {
                    return !f.short_id.startsWith('.')
                })
                return this.sortFilesList(filteredData)
            }
        },
        changeFileType(type) {
            if (type === this.$data.folderTypesLabels.WORKSPACE_FILES) {
                this.$data.folderTypeSelected = this.$data.folderTypesLabels.WORKSPACE_FILES
                this.$router.push({
                    name: 'snapshot-files',
                    params: {
                        oid: this.$route.params.oid,
                        sid: this.$route.params.sid,
                        iid: this.$route.params.iid,
                        snid: this.$route.params.snid,
                        fileArea: this.$data.folderTypesLabels.WORKSPACE_FILES
                    }
                })
            } else if (type === this.$data.folderTypesLabels.PERSONAL_FILES) {
                this.$data.folderTypeSelected = this.$data.folderTypesLabels.PERSONAL_FILES
                this.$router.push({
                    name: 'snapshot-files',
                    params: {
                        oid: this.$route.params.oid,
                        sid: this.$route.params.sid,
                        iid: this.$route.params.iid,
                        snid: this.$route.params.snid,
                        fileArea: this.$data.folderTypesLabels.PERSONAL_FILES
                    }
                })
            }
        },
        selectItem(item) {
            this.selectedItem = item
        },
        openDirectory: function(shortId) {
            this.$data.selected = []
            this.$store.dispatch('snapshotStore/openDirectory', {
                snid: this.$route.params.snid,
                nextDirectory: shortId
            })
        },
        clearCopyAndMoveFiles: function() {
            if (this.$data.filesToMoveOrCopy.length) {
                this.$data.filesToMoveOrCopy = []
            }
        },
        removeFileFromMoveCopyList: function(fid) {
            if (fid) {
                this.$data.filesToMoveOrCopy = this.$data.filesToMoveOrCopy.filter(file => file.fid !== fid)
            }
        },
        addToMoveCopyList: function(filesArray, operation, multipleSelection = false) {
            if (!this.$data.copyOrMoveOperation || operation !== this.$data.copyOrMoveOperation) {
                this.$data.copyOrMoveOperation = operation
                this.$data.filesToMoveOrCopy = []
            }
            const selectedFilesIds = this.$data.filesToMoveOrCopy.map(file => file.fid)
            if (filesArray.length > 0) {
                filesArray.forEach(file => {
                    if (selectedFilesIds.includes(file.fid) === false) {
                        this.$data.filesToMoveOrCopy.push(file)
                    }
                })
                if (multipleSelection === true) {
                    this.$data.selected = []
                }
            }
        },
        duplicateFile: function(fileData) {
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            const successMessage = `${fileData.short_id.toUpperCase()} succesfully submitted for duplication, you can track its progress <a href="${userTasksRoute}">here</a>`
            this.$axios
                .post(`/files/${fileData.fid}/duplicate_async`)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: successMessage,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
                    this.$store.dispatch('userStore/fetchUserTasks')
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to duplicate ${fileData.short_id}`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
        },
        addToStaging: function(data, dataSource) {
            if (data.length > 0) {
                const stagingObjectsBefore = this.stagingObjects.files.length
                var filesWithPaths = []
                data.forEach(file => {
                    var fileObject = file
                    if (file.local_path !== '') {
                        fileObject.filePath = file.local_path + '/' + file.short_id
                    } else {
                        fileObject.filePath = file.short_id
                    }
                    if (file.type === 'folder') {
                        fileObject.filePath = fileObject.filePath + '/'
                    }
                    fileObject.fileType =
                        this.$data.folderTypeSelected === this.$data.folderTypesLabels.WORKSPACE_FILES
                            ? 'workspace'
                            : this.$data.folderTypeSelected === this.$data.folderTypesLabels.PERSONAL_FILES
                            ? 'personal'
                            : 'unknown'
                    filesWithPaths.push(fileObject)
                })
                this.$store.dispatch('snapshotStore/updateStagingObjects', {
                    itemType: 'file',
                    newItems: filesWithPaths,
                    updateMode: 'add'
                })
                if (dataSource === 'selected') {
                    this.$data.selected = []
                } else if (dataSource === 'uploaded') {
                    this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
                    this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                        newData: [],
                        updateMode: 'clear'
                    })
                }
                const stagingObjectsAfter = this.stagingObjects.files.length
                if (stagingObjectsAfter !== stagingObjectsBefore) {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Files added to stage.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Selected files already staged.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                }
            }
            this.$data.panel = 0
        },
        discardUploaded: function() {
            this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                newData: [],
                updateMode: 'clear'
            })
            this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
            this.$data.stageSuggestionSnackbar = false
        },
        endsWithAny: function(suffixes, string) {
            return suffixes.some(suffix => {
                return string.endsWith(suffix)
            })
        },
        fileIcon: function(fileType, fileName) {
            if (fileName) {
                if (fileName.endsWith('.json')) {
                    return 'mdi-code-json'
                } else if (fileName.endsWith('.pdf')) {
                    return 'mdi-adobe-acrobat'
                } else if (this.endsWithAny(['.py', '.R', '.ipynb'], fileName)) {
                    return 'mdi-file-code-outline'
                } else if (this.endsWithAny(['.svg', '.png', '.jpeg', 'jpg', 'eps'], fileName)) {
                    return 'mdi-file-image-outline'
                } else if (this.endsWithAny(['.docx'], fileName)) {
                    return 'mdi-file-word-outline'
                } else {
                    return 'mdi-file-outline'
                }
            } else if (fileType) {
                if (fileType.includes('image')) {
                    return 'mdi-file-image'
                } else if (fileType.includes('application/pdf')) {
                    return 'mdi-adobe-acrobat'
                } else if (fileType.includes('officedocument.wordprocessingml.document')) {
                    return 'mdi-file-word-outline'
                } else if (fileType.includes('application/json')) {
                    return 'mdi-json'
                } else {
                    return 'mdi-file-outline'
                }
            } else {
                return 'mdi-file-outline'
            }
        },
        emptySelected(confirmed) {
            if (confirmed) {
                this.$data.selected = []
            }
        },
        clearMoveList: function() {
            this.$data.filesToMoveOrCopy = []
        },
        fetchFileDownloadToken(fileData) {
            this.$data.fetchingFileToken = true
            this.$data.currentFileDownloadLink = null
            this.$data.downloadLink = this.$axios
                .get(`/files/${fileData.fid}/download_link`)
                .then(response => {
                    this.$data.currentFileDownloadLink = `/api/downloads/${response.data}`
                })
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Download of ${fileData.short_id} failed!`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.fetchingFileToken = false
                })
        },
        previewFile(fileData) {
            this.$data.downloadingFileForPreview = true
            this.$axios.get(`/files/${fileData.fid}/download_link`).then(response => {
                this.$axios
                    .get(`/downloads/${response.data}`, { timeout: 300000, responseType: 'blob' })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                        this.$data.previewBlobUrl = url
                        this.$data.previewDialog = true
                        this.$data.previewLongId = fileData.short_id
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Download of ${fileData.short_id} failed!`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.$data.downloadingFileForPreview = false
                    })
            })
        },
        setFolderTypeAndFetch(folderType, fileLevel) {
            this.$store.dispatch('snapshotStore/setFolderType', folderType)
            this.$store.dispatch('snapshotStore/setTreeLevel', {
                snid: this.$route.params.snid,
                level: fileLevel
            })
        }
    },
    watch: {
        folderTypeSelected(to, from) {
            const level = this.$route.params.localPath === undefined ? 1 : this.$route.params.localPath.split('/').length
            this.setFolderTypeAndFetch(to, level)
        },
        showUploadSnackbar: function(nextValue, prevValue) {
            this.$data.stageSuggestionSnackbar = nextValue
        },
        currentFiles: {
            handler(to, from) {
                this.$data.selected = to.filter(i => this.$data.selected.filter(a => a.fid === i.fid).length > 0)
                const readmeMD = to.filter(f => {
                    return f.short_id && f.short_id.toLowerCase() === 'readme.md'
                })
                if (readmeMD.length) {
                    if (readmeMD[0].last_modified !== this.$data.readmeMDLastModified) {
                        this.$axios
                            .get(`/files/${readmeMD[0].fid}/download`, {
                                timeout: 300000,
                                responseType: 'blob' // important
                            })
                            .then(response => {
                                var reader = new FileReader()
                                reader.addEventListener('loadend', e => {
                                    const text = e.srcElement.result
                                    this.$data.readme_md_content = text
                                })
                                reader.readAsText(response.data)
                            })
                            .catch(e => {
                                console.log(e)
                                this.$data.readme_md_content = ''
                                this.$data.readmeMDLastModified = ''
                            })
                        this.$data.readmeMDLastModified = readmeMD[0].last_modified_timestamp
                    }
                } else {
                    this.$data.readme_md_content = ''
                    this.$data.readmeMDLastModified = ''
                }
            },
            immediate: true
        },
        $route: {
            handler(to, from) {
                if (to && from && to.params.snid !== from.params.snid) {
                    this.resetInitialState()
                }
                if (to.name !== 'snapshot-files') {
                    this.$data.selected = []
                    this.$data.search = ''
                    this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
                    this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                        newData: [],
                        updateMode: 'clear'
                    })
                    this.clearMoveList()
                }
            },
            immediate: true
        }
    },
    components: {
        UppyUpload,
        RenameButton,
        DeleteButton,
        FileStatus,
        PdfViewer,
        CreateFolder,
        TheSnapshotMoveAndCopyFiles,
        TheSnapshotFileEditor
    }
}
</script>

<style scoped>
.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    padding: 45px;
}

@media (max-width: 767px) {
    .markdown-body {
        padding: 15px;
    }
}
</style>
